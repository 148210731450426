import { Controller } from '@hotwired/stimulus';
import { BehaviorSubject, delay, filter, map, queueScheduler, scheduled, Subject } from 'rxjs';
import { delayWhen } from 'rxjs';

export default class NotificationsController extends Controller {
  static targets = ['notification', 'template', 'container'];
  static classes = ['enterTransition', 'leaveTransition', 'enterFrom', 'enterTo', 'leaveFrom', 'leaveTo', 'initial'];
  static values = {
    autoCloseDelay: Number,
  };

  /** @type {Subject<HTMLElement>} */
  #autoRemoveQueue = new Subject();
  #isBusy = new BehaviorSubject(false);

  #handleNotify = (evt) => {
    const {type, message, details} = (evt.detail || {});
    const notification = this.templateTarget.content.cloneNode(true);
    notification.querySelector('[data-type]')?.setAttribute('data-type', type);
    const messageNode = notification.querySelector('[data-message]');
    if (messageNode) { messageNode.innerHTML = message;}
    const detailsNode = notification.querySelector('[data-details]');
    if (detailsNode && details) { detailsNode.innerHTML = details;}

    this.containerTarget.append(notification);
  };

  connect() {
    this.#autoRemoveQueue
      .pipe(
        delay(this.autoCloseDelayValue),
      ).subscribe(el => {
      if (el.parentNode) {
        this.#removeNotification(el);
      }
    });

    document.addEventListener('notifications:notify', this.#handleNotify);
  }

  disconnect() {
    document.removeEventListener('notifications:notify', this.#handleNotify);
  }

  async notificationTargetConnected(el) {
    await new Promise(done => {
      el.classList.add(...this.enterTransitionClasses, ...this.enterFromClasses);
      el.classList.remove(this.initialClass);
      setTimeout(() => done());
    });
    el.addEventListener('transitionend', (evt) => {
      el.classList.remove(...this.enterToClasses);
    }, {once: true});

    el.classList.add(...this.enterToClasses);
    el.classList.remove(...this.enterFromClasses);

    if (this.autoCloseDelayValue) {
      this.#autoRemoveQueue.next(el);
    }
  }

  /**
   * Closes and removes a notification
   * @param evt {Event}
   */
  async dismiss(evt) {
    /** @type {HTMLElement} */
    const notification = evt.currentTarget.closest('[data-notifications-target="notification"]');
    if (notification) {
      await this.#removeNotification(notification);
    }
  }

  async #removeNotification(notification) {
    this.#isBusy.next(true);
    return await new Promise(done => {
      notification.classList.add(...this.leaveTransitionClasses, ...this.leaveFromClasses);
      setTimeout(() => done());
    }).then(() => new Promise(done => {
      notification.addEventListener('transitionend', () => {
        notification.remove();
        done();
      });
      notification.classList.add(...this.leaveToClasses);
      notification.classList.remove(...this.leaveFromClasses);
    })).then(() => this.#isBusy.next(false));
  }
}
