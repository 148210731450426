import { Controller } from '@hotwired/stimulus';

export default class ModalController extends Controller {
  static targets = ['backdrop', 'panel', 'modal'];
  static classes = [
    'backdropEntering',
    'backdropEnterFrom',
    'backdropEnterTo',
    'backdropLeaving',
    'backdropLeaveFrom',
    'backdropLeaveTo',
    'panelEntering',
    'panelEnterFrom',
    'panelEnterTo',
    'panelLeaving',
    'panelLeaveFrom',
    'panelLeaveTo',
  ];

  static values = {
    open: Boolean,
  };

  toggle(evt) {
    console.log('Opening');
    evt.preventDefault();

    this.openValue = !this.openValue;
    this.#updateModal();
  }

  close(evt) {
    evt.preventDefault();
    this.openValue = false;
    this.#updateModal();
  }

  #updateModal() {
    if (this.openValue) {
      this.backdropTarget.classList.add(...this.backdropEnteringClasses);
      this.backdropTarget.classList.add(...this.backdropEnterFromClasses);
      this.panelTarget.classList.add(...this.panelEnteringClasses);
      this.panelTarget.classList.add(...this.panelEnterFromClasses);
      this.modalTarget.classList.remove('tw-hidden');
      setTimeout(() => {
        this.backdropTarget.classList.remove(...this.backdropEnterFromClasses);
        this.backdropTarget.classList.add(...this.backdropEnterToClasses);
        this.panelTarget.classList.remove(...this.panelEnterFromClasses);
        this.panelTarget.classList.add(...this.panelEnterToClasses);
      }, 10);
      this.backdropTarget.addEventListener('transitionend', () => {
        this.backdropTarget.classList.remove(...this.backdropEnterToClasses);
      }, {once: true});
      this.panelTarget.addEventListener('transitionend', () => {
        this.panelTarget.classList.remove(...this.panelEnterToClasses);
      }, {once: true});
    } else {
      this.backdropTarget.classList.add(...this.backdropLeavingClasses);
      this.backdropTarget.classList.add(...this.backdropLeaveFromClasses);
      this.panelTarget.classList.add(...this.panelLeavingClasses);
      this.panelTarget.classList.add(...this.panelLeaveFromClasses);
      this.modalTarget.classList.remove('tw-hidden');
      setTimeout(() => {
        this.backdropTarget.classList.remove(...this.backdropLeaveFromClasses);
        this.backdropTarget.classList.add(...this.backdropLeaveToClasses);
        this.panelTarget.classList.remove(...this.panelLeaveFromClasses);
        this.panelTarget.classList.add(...this.panelLeaveToClasses);
      }, 10);
      this.panelTarget.addEventListener('transitionend', () => {
        this.modalTarget.classList.add('tw-hidden');
        setTimeout(() => {
          this.panelTarget.classList.remove(...this.panelLeaveToClasses);
          this.backdropTarget.classList.remove(...this.backdropLeaveToClasses);
        }, 10);
      }, {once: true});
    }
  }

}
