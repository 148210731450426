<script>
  import { deserialize } from 'deserialize-json-api';
  import { computePosition } from '@floating-ui/dom';

  import Product from './Product.svelte';
  import { debounce } from 'lodash';

  export let showDropdown = false;
  let products = [];
  let anchor;

  async function handleInput(evt) {
    if (evt.target.value.length > 2) {
      showDropdown = true;

      const url = new URL(window.location);
      url.pathname = '/api/v2/storefront/products';
      url.searchParams.set('filter[name]', evt.target.value);
      url.searchParams.set('include', 'images');
      const res = await fetch(url, {credentials: 'same-origin'});
      const body = await res.json().then(body => deserialize(body));
      products = body.data;
    } else {
      showDropdown = false;
    }
  }

  function updatePosition(node) {
    computePosition(anchor, node).then(({x, y}) => {
      Object.assign(node.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  const inputHandler = debounce(handleInput, 200);

</script>

<div class="tw-w-full tw-max-w-lg lg:tw-max-w-xs" bind:this={anchor}>
    <label for="search" class="tw-sr-only">Search</label>
    <div class="tw-relative">
        <div class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
            <svg class="tw-h-5 tw-w-5 tw-text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"/>
            </svg>
        </div>
        <input id="search"
               name="search"
               class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-bg-white tw-py-1.5 tw-pl-10 tw-pr-3 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
               placeholder="Search"
               on:input={inputHandler}
               type="search">
    </div>
</div>
<template data-search-target="template">
</template>
{#if (showDropdown)}
    <div class="tw-absolute tw-left-0 tw-top-0 tw-z-10 tw-mt-5 tw-w-screen tw-max-w-max tw-px-4" use:updatePosition>
        <div class="tw-w-screen tw-max-w-md tw-max-h-[80vh] tw-flex-auto tw-divide-y tw-divide-gray-100 tw-overflow-y-scroll tw-rounded-3xl tw-bg-white tw-text-sm tw-leading-6 tw-shadow-lg tw-ring-1 tw-ring-gray-900/5">
            {#each products as product (product.id)}
                <Product product={product}></Product>
            {/each}
        </div>
    </div>
{/if}
