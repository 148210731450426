import { Controller } from '@hotwired/stimulus';
import ProductSearch from '../components/ProductSearch.svelte';

export default class SearchController extends Controller {

  #searcher = null;

  connect() {
    document.addEventListener('click', this.#clickOutside);

    this.element.replaceChildren('');
    this.#searcher = new ProductSearch({
      target: this.element,
    });
  }

  disconnect() {
    document.removeEventListener('click', this.#clickOutside);
    this.#searcher?.$destroy();
  }

  #clickOutside = (evt) => {
    if (!this.element.contains(evt.target)) {
      this.#searcher?.$set({showDropdown: false});
    }
  };

}
