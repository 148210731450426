import { Controller } from '@hotwired/stimulus';

export default class TabsController extends Controller {
  static targets = ['tabs', 'panels'];
  static values = {
    selected: String,
  };

  select(evt) {
    evt.preventDefault();

    const target = evt.currentTarget;
    if (target.getAttribute('aria-selected') === 'true') return;

    const selectedElement = this.tabsTarget.querySelector('[aria-selected=true]');
    if (selectedElement) {
      selectedElement.setAttribute('aria-selected', false);
    }
    target.setAttribute('aria-selected', true);
    const panel = this.panelsTarget.querySelector('#' + target.getAttribute('aria-controls'));
    const selectedPanel = this.panelsTarget.querySelector('[data-tab-active=true]');
    if (selectedPanel) {
      selectedPanel.setAttribute('data-tab-active', false);
    }
    if (panel) {
      panel.setAttribute('data-tab-active', true);
    }

    this.selectedValue = target.id;
  }
}
