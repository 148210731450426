import { Controller } from '@hotwired/stimulus';
import { autoPlacement, autoUpdate, computePosition, offset } from '@floating-ui/dom';

export default class DropdownController extends Controller {
  static targets = ['popout', 'anchor'];
  static values = {
    open: Boolean,
  };

  #cleanup = null;
  #initialized = false;

  toggle(evt) {
    evt.preventDefault();
    this.openValue = !this.openValue;
  }

  connect() {
    document.addEventListener('click', this.#clickOutside);
  }

  disconnect() {
    document.removeEventListener('click', this.#clickOutside);
  }

  openValueChanged(value, previousValue) {
    if (!this.#initialized) {
      if (value) {
        this.popoutTarget.classList.remove('tw-dropdown--hidden');
      } else {
        if (!this.popoutTarget.classList.contains('tw-dropdown--hidden')) {
          this.popoutTarget.classList.add('tw-dropdown--hidden');
        }
      }
      this.#initialized = true;
      return;
    }

    if (value) {
      this.#cleanup = autoUpdate(this.anchorTarget, this.popoutTarget, () => {
        this.#updatePosition();
      });
      this.popoutTarget.classList.add('tw-dropdown--hiding');
      this.popoutTarget.classList.remove('tw-dropdown__leaving-transition');
      this.popoutTarget.classList.add('tw-dropdown__enter-transition');
      this.popoutTarget.classList.remove('tw-dropdown--hidden');
      setTimeout(() => {
        this.popoutTarget.classList.remove('tw-dropdown--hiding');
      });
    } else {
      if (this.#cleanup) {
        this.#cleanup();
      }

      this.popoutTarget.classList.remove('tw-dropdown__enter-transition');
      this.popoutTarget.classList.add('tw-dropdown__leaving-transition', 'tw-dropdown--hiding');
      this.popoutTarget.addEventListener('transitionend', () => {
        this.popoutTarget.classList.add('tw-dropdown--hidden');
      }, {once: true});
    }
  }

  #updatePosition() {
    //@type {ComputePositionConfig}
    const options = {
      middleware: [autoPlacement({
        alignment: 'bottom-end',
        allowedPlacements: ['top-end', 'bottom-end'],
      }), offset(5)],
    };

    computePosition(this.anchorTarget, this.popoutTarget, options).then(({x, y}) => {
      Object.assign(this.popoutTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  #clickOutside = (evt) => {
    if (this.openValue) {
      if (!(this.popoutTarget?.contains(evt.target) || this.anchorTarget?.contains(evt.target))) {
        this.openValue = false;
      }
    }
  };

}
