import { Controller } from '@hotwired/stimulus';

export default class DisclosureController extends Controller {
  static targets = ['disclosure', 'expandIcon', 'collapseIcon'];
  static classes = ['open', 'closed', 'openIcon', 'closedIcon'];
  static values = {
    open: Boolean,
  };

  toggle(evt) {
    evt.preventDefault();
    this.openValue = !this.openValue;

    this.#updateStatus();
  }

  #updateStatus() {
    if (this.openValue) {
      if (this.expandIconTarget) {
        if (!this.expandIconTarget.classList.contains('tw-hidden')) {
          this.expandIconTarget.classList.add('tw-hidden');
        }
      }
      if (this.collapseIconTarget) {
        this.collapseIconTarget.classList.remove('tw-hidden');
      }
      if (this.disclosureTarget) {
        this.disclosureTarget.classList.remove('tw-hidden');
      }
    } else {
      if (this.expandIconTarget) {
        this.expandIconTarget.classList.remove('tw-hidden');
      }
      if (this.collapseIconTarget) {
        if (!this.collapseIconTarget.classList.contains('tw-hidden')) {
          this.collapseIconTarget.classList.add('tw-hidden');
        }
      }
      if (this.disclosureTarget) {
        if (!this.disclosureTarget.classList.contains('tw-hidden')) {
          this.disclosureTarget.classList.add('tw-hidden');
        }
      }
    }

  }

}
