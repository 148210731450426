import { Controller } from '@hotwired/stimulus';

export default class WishlistController extends Controller {
  static values = {
    token: String,
  };

  connect() {

  }

  disconnect() {

  }

  async addItem(evt) {
    await fetch(`/api/v2/storefront/wishlists/${token}/add_item`, {
      method: 'post',
      credentials: 'same-origin',
      mode: 'same-origin',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        'Authorization': 'Bearer ' + SpreeAPI.oauthToken,
      },
      body: JSON.stringify({
        variant_id: variantId,
        quantity: 1,
      }),
    });
  }

  removeItem(evt) {
    const token = this.tokenValue;
    const id = evt.params.item;

    fetch(`/api/v2/storefront/wishlists/${token}/remove_item/${id}`, {
      method: 'delete',
      credentials: 'same-origin',
      mode: 'same-origin',
      headers: {
        'Authorization': 'Bearer ' + SpreeAPI.oauthToken,
      },
    }).then((r) => {
      if (r.ok) {
        const el = this.element.querySelector(`#wished_item_${id}`);
        if (el) { el.remove();}
      }
    });
  }

  addToCart(evt) {
    evt.preventDefault();
    const variantId = evt.params.variant;

    // noinspection DuplicatedCode
    Spree.ensureCart(() => {
      fetch('/api/v2/storefront/cart/add_item', {
        method: 'post',
        credentials: 'same-origin',
        mode: 'same-origin',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'X-Spree-Order-Token': SpreeAPI.orderToken,
        },
        body: JSON.stringify({
          variant_id: variantId,
          quantity: 1,
        }),
      }).then(res => {
        if (res.ok) {
          document.dispatchEvent(new Event('cart:updated'));
          this.dispatch('notify', {prefix: 'notifications', detail: {type: 'success', message: 'Product added to cart'}, target: evt.target});
          evt.target.setAttribute('disabled', '');
        } else {
          res.json().then(error => {
            this.dispatch('notify', {prefix: 'notifications', detail: {type: 'error', message: error.error}, target: evt.target});
          });
        }
      });
    });

  }
}
