import { Controller } from '@hotwired/stimulus';

export default class SpreeController extends Controller {

  connect() {
    const event = new Event('spree:load');
    document.dispatchEvent(event);

    document.addEventListener('cart:updated', this.#cartUpdatedHandler);
  }

  disconnect() {
    document.removeEventListener('cart:updated', this.#cartUpdatedHandler);
  }

  #cartUpdatedHandler = (evt) => {
    Turbo.visit(Spree.localizedPathFor('cart_link'), {frame: 'link-to-cart-frame'});
  };

}
