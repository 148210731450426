import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import { deserialize } from 'deserialize-json-api';

export default class ProductController extends Controller {
  static targets = ['addToCart', 'addToWishlist', 'removeFromCart', 'removeFromWishlist'];
  static outlets = ['spree'];
  static values = {
    id: String,
    checkAvailability: Boolean,
  };

  connect() {
  }

  async reloadButtons(evt) {
    const location = document.location.pathname;

    const res = await get(location + '/links', {
      credentials: 'same-origin',
      mode: 'same-origin',
      headers: {'Accept': 'text/vnd.turbo-stream.html'},
      responseKind: 'turbo-stream',
    });
  }

  async addToWishlist(evt) {
    const variantId = this.idValue;
    try {
      const res = await fetch('/api/v2/storefront/wishlists/default', {
        credentials: 'same-origin',
        mode: 'same-origin',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Authorization': 'Bearer ' + SpreeAPI.oauthToken,
        },
      }).then(r => r.ok ? r.json() : r.json().then(e => Promise.reject({...e, status: r.status})));

      const wishList = deserialize(res).data;
      const token = wishList.token;

      const itemAddedResult = await fetch(`/api/v2/storefront/wishlists/${token}/add_item`, {
        method: 'post',
        credentials: 'same-origin',
        mode: 'same-origin',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Authorization': 'Bearer ' + SpreeAPI.oauthToken,
        },
        body: JSON.stringify({
          variant_id: variantId,
          quantity: 1,
        }),
      });

      if (itemAddedResult.ok) {
        this.dispatch('notify', {prefix: 'notifications', detail: {type: 'success', message: 'Product added to wishlist'}, target: evt.target});
        this.dispatch('updated', {prefix: 'wishlist', detail: {type: 'success', variant: variantId}});
      } else {
        const body = await res.json();
        this.dispatch('notify', {
          prefix: 'notifications',
          detail: {
            type: 'error',
            message: body.error,
          },
          target: evt.target,
        });
      }

    } catch (error) {
      if (error.status === 403) {
        this.dispatch('notify', {
          prefix: 'notifications',
          detail: {
            type: 'error',
            message: 'You need to be logged in to use the wishlist.',
          },
          target: evt.target,
        });
      } else {
        this.dispatch('notify', {
          prefix: 'notifications',
          detail: {
            type: 'error',
            message: error.error,
          },
          target: evt.target,
        });
      }
    }
  }

  async removeFromWishlist(evt) {
    try {
      const variantId = this.idValue;
      const res = await fetch(`/api/v2/storefront/wishlists/default?is_variant_included=${variantId}&include=wished_items,wished_items.variant`, {
        credentials: 'same-origin',
        mode: 'same-origin',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Authorization': 'Bearer ' + SpreeAPI.oauthToken,
        },
      }).then(r => r.ok ? r.json() : r.json().then(e => Promise.reject({...e, status: r.status})));

      const {data: wishlist} = deserialize(res);
      const token = wishlist.token;
      const [item, ..._] = (wishlist.wished_items || []).filter(w => w.variant.id === variantId);
      if (item.id) {
        const deleted = await fetch(`/api/v2/storefront/wishlists/${token}/remove_item/${item.id}`, {
          method: 'delete',
          credentials: 'same-origin',
          mode: 'same-origin',
          headers: {
            'Content-Type': 'application/vnd.api+json',
            'Authorization': 'Bearer ' + SpreeAPI.oauthToken,
          },
        });

        if (deleted.ok) {
          this.dispatch('notify', {prefix: 'notifications', detail: {type: 'success', message: 'Product removed from wishlist'}, target: evt.target});
          this.dispatch('updated', {prefix: 'wishlist', detail: {type: 'success', variant: variantId}});
        } else {
          this.dispatch('notify', {prefix: 'notifications', detail: {type: 'error', message: 'Unable to remove the product from the wishlist'}, target: evt.target});
        }
      }
    } catch (error) {
      this.dispatch('notify', {prefix: 'notifications', detail: {type: 'error', message: error.error}, target: evt.target});
    }
  }

}
