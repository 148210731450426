import { Controller } from '@hotwired/stimulus';

import Swiper from 'swiper';
import { Thumbs, Navigation, Pagination, Scrollbar } from 'swiper/modules';

export default class ProductGalleryController extends Controller {
  static targets = ['preview', 'thumbs'];

  #thumb = null;
  #preview = null;

  connect() {
    this.#thumb = new Swiper(this.thumbsTarget, {
      modules: [Pagination],
      loop: true,
      spaceBetween: 10,
      slidesPerView: 5,
      slidesPerGroup: 5,
      watchSlidesProgress: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet(idx, className) {
          return `
            <div class="tw-group tw-relative tw-flex tw-h-5 tw-w-5 tw-flex-shrink-0 tw-items-center tw-justify-center ${className}" aria-hidden="true">
              <span class="tw-absolute tw-h-4 tw-w-4 tw-rounded-full tw-bg-indigo-200 tw-hidden group-[.swiper-pagination-bullet-active]:tw-inline-block"></span>
              <div class="tw-z-10 tw-h-2 tw-w-2 tw-rounded-full tw-bg-gray-300 hover:tw-bg-gray-400 group-[.swiper-pagination-bullet-active]:tw-bg-indigo-600"></div>
            </div>
`;
        },
      },
    });

    this.#preview = new Swiper(this.previewTarget, {
      modules: [Thumbs, Navigation],
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: this.#thumb,
      },
    });
  }

  destroy() {
    this.#preview?.destroy();
    this.#thumb.destroy();
  }
}
