import { Controller } from '@hotwired/stimulus';

import Swiper from 'swiper';
import { Thumbs, Navigation, Pagination, Scrollbar, Autoplay, Grid } from 'swiper/modules';
import 'swiper/css';

export default class CarouselController extends Controller {
  static targets = ['preview', 'next', 'prev'];
  static values = {
    slidesPerView: Number,
    slidesPerGroup: Number,
    paginationEnabled: Boolean,
    paginationDynamic: Boolean,
    navigation: Boolean,
    loop: Boolean,
    autoplay: Boolean,
    breakpoints: Object,
  };

  #swiper = null;

  connect() {
    const pagination = this.element.querySelector('[data-carousel-pagination]');
    const next = this.element.querySelector('[data-carousel-next]');
    const prev = this.element.querySelector('[data-carousel-prev]');

    const options = {
      modules: [Thumbs, Navigation, Pagination, Scrollbar, Autoplay, Grid],
      spaceBetween: 10,
      loop: !!this.loopValue,
      slidesPerView: this.slidesPerViewValue,
      slidesPerGroup: this.hasSlidesPerGroupValue ? this.slidesPerGroupValue : 1,
      grid: true,
      breakpoints: this.hasBreakpointsValue ? this.breakpointsValue : null,
      pagination: {
        el: pagination,
        dynamicBullets: !!this.paginationDynamicValue,
        dynamicMainBullets: !!this.paginationDynamicValue ? 6 : 1,
        clickable: true,
        renderBullet(idx, className) {
          return `
            <div class="tw-group tw-relative tw-flex tw-h-5 tw-w-5 tw-flex-shrink-0 tw-items-center tw-justify-center ${className}" aria-hidden="true">
              <span class="tw-absolute tw-h-4 tw-w-4 tw-rounded-full tw-bg-indigo-200 tw-hidden group-[.swiper-pagination-bullet-active]:tw-inline-block"></span>
              <div class="tw-z-10 tw-h-2 tw-w-2 tw-rounded-full tw-bg-gray-300 hover:tw-bg-gray-400 group-[.swiper-pagination-bullet-active]:tw-bg-indigo-600"></div>
            </div>
`;
        },
      },
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      autoplay: this.autoplayValue,
    };
    this.#swiper = new Swiper(this.previewTarget, options);
  }

  disconnect() {
    this.#swiper?.destroy();
  }
}
