import { Controller } from '@hotwired/stimulus';

export default class VideoController extends Controller {

  #observer = null;

  connect() {
    this.#observer = new IntersectionObserver(entries => this.#visibilityUpdate(entries));
    this.#observer.observe(this.element);
  }

  #visibilityUpdate(entries) {
    for (let i in entries) {
      const {playing} = this.element.state;
      if (!i.isIntersecting && playing) {
        this.element.pause();
      }
    }
  }
}
