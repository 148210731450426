<script>
  import { find, head } from 'lodash';
  export let product = {};
  let thumb = find((head(product.images)?.styles || []), s => s.size === '448x600>');
</script>

<div class="tw-group tw-relative tw-flex tw-gap-x-6 tw-rounded-lg tw-p-4 hover:tw-bg-gray-50">
    <div>
        <img src="{thumb.url}" alt="" class="tw-aspect-[3/4] tw-w-14 tw-h-auto">
    </div>
    <div class="tw-flex-1">
        <a href="/products/{product.slug}" class="tw-font-semibold tw-text-gray-900">{product.name}</a>
        <p class="tw-mt-1 tw-text-gray-600" data-attr="desc">{@html product.meta_description || ''}</p>
    </div>
    <div>
        <span>{product.display_price}</span>
    </div>
</div>
