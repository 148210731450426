import { Application } from '@hotwired/stimulus';

import AccountController from './controllers/account_controller';
import CarouselController from './controllers/carousel_controller';
import CartController from './controllers/cart_controller';
import DisclosureController from './controllers/disclosure_controller';
import DropdownController from './controllers/dropdown_controller';
import FormController from './controllers/form_controller';
import ModalController from './controllers/modal_controller';
import NotificationsController from './controllers/notifications_controller';
import ProductController from './controllers/product_controller';
import ProductGalleryController from './controllers/product_gallery_controller';
import SearchController from './controllers/search_controller';
import SpreeController from './controllers/spree_controller';
import TabsController from './controllers/tabs_controller';
import WishlistController from './controllers/wishlist_controller';
import VideoController from './controllers/video_controller';

const Stimulus = Application.start();
Stimulus.debug = false;

window.Stimulus = Stimulus;

Stimulus.register('account', AccountController);
Stimulus.register('carousel', CarouselController);
Stimulus.register('cart', CartController);
Stimulus.register('disclosure', DisclosureController);
Stimulus.register('dropdown', DropdownController);
Stimulus.register('form', FormController);
Stimulus.register('modal', ModalController);
Stimulus.register('notifications', NotificationsController);
Stimulus.register('product', ProductController);
Stimulus.register('product-gallery', ProductGalleryController);
Stimulus.register('search', SearchController);
Stimulus.register('spree', SpreeController);
Stimulus.register('tabs', TabsController);
Stimulus.register('wishlist', WishlistController);
Stimulus.register('video', VideoController);
