import { Controller } from '@hotwired/stimulus';
import { deserialize } from 'deserialize-json-api';

export default class AccountController extends Controller {

  #products = [];
  isReady = false;

  #queue = [];

  async connect() {
    await this.#fetchTokens();
    await Promise.allSettled([this.#ensureCart()]);
  }

  check(evt) {
    if (this.isReady) {

    }
    console.log('Check: ', evt);
  }

  async #fetchTokens() {
    const res = await fetch(Spree.routes.api_tokens, {
      method: 'GET',
      credentials: 'same-origin',
    });
    if (res.ok) {
      const body = await res.json();
      SpreeAPI.orderToken = body.order_token;
      SpreeAPI.oauthToken = body.oauth_token;
      Spree.apiTokensFetched = true;
    }
  }

  async #fetchUserCart() {
    if (!Spree.apiTokensFetched) {
      await this.#fetchTokens();
    }
  }

  async #ensureCart() {
    if (SpreeAPI.orderToken) return;

    const res = await fetch(Spree.routes.ensure_cart, {
      method: 'POST',
      credentials: 'same-origin',
    });
    if (res.ok) {
      const body = await res.json();
      SpreeAPI.orderToken = body.token;
    }
  }

  async #fetchOwned() {
    if (!SpreeAPI.oauthToken) return;

    const res = await fetch('/api/v2/storefront/account/products', {
      credentials: 'same-origin',
      headers: {
        authorization: `Bearer ${SpreeAPI.oauthToken}`,
      },
    });

    if (res.ok) {
      const body = await res.json();
      const products = deserialize(body);
      console.log('Products fetched: ', products);
      this.#products = products;
      this.isReady = true;
    }
  }
}
